import { Controller } from 'stimulus';
import Splide from '@splidejs/splide';

export default class extends Controller {
  static targets = ['form', 'slide'];

  initialize() {
    this.splide = new Splide(this.slideTarget, {
      autoWidth: true,
      perPage: this.perPage(),
      pagination: false,
    }).mount();
  }

  resizeWindow() {
    this.splide.options.perPage = this.perPage();
    this.splide.refresh();
  }

  perPage() {
    const widnowWidth = window.innerWidth;
    if (widnowWidth > 991) {
      return 3;
    }
    if (widnowWidth > 691) {
      return 2;
    }
    if (widnowWidth <= 691) {
      return 1;
    }
  }

  submit(e) {
    if (!this.validateFileSize()) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  validateFileSize() {
    const elements = this.formTarget.querySelectorAll('input[type="file"]');
    const files = Array.from(elements)
      .map((element) => element.files[0])
      .filter((file) => file);
    if (files.length < 1) {
      Akds.notify('画像を選択してください。', 'danger', 3000);
      return false;
    }

    const maxSize = 20 * 1024 * 1024; // 20MB
    const totalSize = files.reduce((acc, file) => acc + file.size, 0);

    if (totalSize > maxSize) {
      Akds.notify(
        'ファイルサイズの合計は20MB以下にしてください。',
        'danger',
        3000
      );
      return false;
    }

    return true;
  }
}
